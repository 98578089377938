import React, { createContext, useState, useEffect } from "react";
import { genSign, getBrowserFingerPrint } from "../utils/userSign.js";

const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [userSign, setUserSign] = useState("");
  const [browserFingerPrint, setBrowserFingerPrint] = useState("");

  useEffect(() => {
    /* genSign(userSign, (err, uSign) => {
      setUserSign(uSign);
    }); */
    const getUS = async () => {
      try {
        const uSign = await genSign(userSign);
        setUserSign(uSign);
      } catch (err) {
        setUserSign("");
      }
    };
    const getBF = async () => {
      const bFingerPrint = await getBrowserFingerPrint(browserFingerPrint);
      setBrowserFingerPrint(bFingerPrint);
    };

    getUS();
    getBF();
  }, []);

  if (userSign.length === 0 || browserFingerPrint.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <SessionContext.Provider
      value={{
        userSign,
        browserFingerPrint,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, SessionContext };
