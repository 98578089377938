import React, { useRef, useEffect, useState, useContext } from "react";
import "../styles/GiftCode.scss";
import { useIntl } from "react-intl";
import { webCodeRedeem } from "../services/apiRequest.js";
import { SessionContext } from "../contexts/sessionContext.js";
import { useNavigate } from "react-router-dom";

const GiftCode = (props) => {
  const navigate = useNavigate();
  const { LANG, showChatHandler, STYLE } = props;
  const intl = useIntl();
  const classNamePre = STYLE === "1" ? "GiftCode" : "GiftCode2";
  const abortController = useRef(new AbortController());
  const [redeemMsg, setRedeemMsg] = useState("");
  const codeVal = useRef(false);
  const [showRedeemDialog, setShowRedeemDialog] = useState(false);
  const { browserFingerPrint } = useContext(SessionContext);

  useEffect(() => {
    const controller = abortController.current;
    return () => {
      controller.abort();
    };
  }, []);

  const submit = () => {
    //send to check gift code
    let redeemMessage = "";
    let postData = {};
    const inputTextBox = document.getElementById("gift_code");
    let codeText = inputTextBox.value.trim();
    if (codeText !== "") {
      postData = { code: codeText, agentId: browserFingerPrint };
      webCodeRedeem(LANG, postData, abortController.current, (err, res) => {
        if (err) {
          redeemMessage = err;
          setRedeemMsg(redeemMessage);
          setShowRedeemDialog(true);
        } else {
          if (!res.pass) {
            redeemMessage = res.error.message;
            setRedeemMsg(redeemMessage);
            codeVal.current = false;
            setShowRedeemDialog(true);
          } else {
            const codeCredit = res.codeCredit;
            redeemMessage = `${intl.formatMessage(
              {
                id: "GiftCode.codeCredit",
              },
              { codeCredit }
            )}`;
            codeVal.current = true;
            setRedeemMsg(redeemMessage);
            if (codeCredit > 0) {
              displayChatProcess();
            }
          }
        }
      });
    }
  };

  const quiteRedeeem = () => {
    navigate("/");
    setShowRedeemDialog(false);
  };

  const displayChatProcess = () => {
    // open ChatProcess
    navigate("/chat");
    setShowRedeemDialog(false);
    showChatHandler(true);
  };

  return (
    <>
      <div className={classNamePre}>
        {showRedeemDialog && (
          <div className={`${classNamePre}-redeem`}>
            <div className={`${classNamePre}-redeemBg`}>
              <div
                className={
                  codeVal.current === false
                    ? `${classNamePre}-errorMsg`
                    : `${classNamePre}-changeLine`
                }
              >
                {redeemMsg}
              </div>
              <div className={`${classNamePre}-redeemButtonFrame`}>
                {codeVal.current && (
                  <div
                    className={`${classNamePre}-redeemButtonFrame__button`}
                    onClick={displayChatProcess}
                  >
                    {intl.formatMessage({ id: "GiftCode.yes" })}
                  </div>
                )}
                <div
                  className={`${classNamePre}-redeemButtonFrame__button`}
                  onClick={quiteRedeeem}
                >
                  {intl.formatMessage({ id: "ChatProcess.closeConfirm" })}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={`${classNamePre}-content`}>
          <div className={`${classNamePre}-inputHints`}>
            {intl.formatMessage({ id: "GiftCode.pleaseEnterCode" })}
          </div>
          <input
            id="gift_code"
            type="text"
            className={`${classNamePre}-input`}
            maxLength="17"
            size="17"
          />
          <div onClick={submit} className={`${classNamePre}-sendBtn`}>
            {intl.formatMessage({ id: "ChatProcess.sendContent" })}
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCode;
