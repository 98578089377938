import React from "react";
import "../styles/MainContent.scss";
import { useState } from "react";
import { useIntl } from "react-intl";
import ChatProcess from "./ChatProcess.js";

const MainContent = (props) => {
  const { LANG } = props;

  const intl = useIntl();
  const [showResult, setShowResult] = useState(false);
  /* const userQuestion = useRef(null); */
  const CSSName = LANG === "en" ? "MainContentEn" : "MainContent";
  const STYLE = LANG === "en" ? "1" : "2";

  const handleSend = () => {
    setShowResult(true);
  };

  return (
    <div className={CSSName}>
      <img
        /* src={`${process.env.PUBLIC_URL}/imgs/chatbg_pc.png`} */
        src={`${process.env.REACT_APP_IMG_URL}web_img/chatbg_pc.png`}
        alt=""
        className="MainContent-imgpc"
      />
      <img
        /* src={`${process.env.PUBLIC_URL}/imgs/chatbg_mobile.png`} */
        src={`${process.env.REACT_APP_IMG_URL}web_img/chatbg_mobile.png`}
        alt=""
        className="MainContent-imgmobile"
      />
      <div className="MainContent-inputframe" onClick={handleSend}>
        {intl.formatMessage({ id: "MainContent.start" })}
        {/*  <div className="MainContent-inputframe_sendBtn" /> */}
      </div>
      <div className="MainContent-animation">
        {LANG === "en" ? (
          <img
            /* src={`${process.env.PUBLIC_URL}/imgs/chat_A_EN.png`} */
            src={`${process.env.REACT_APP_IMG_URL}web_img/chat_A_EN.png`}
            className="MainContent-animation_chat"
            alt=""
          />
        ) : (
          <img
            /* src={`${process.env.PUBLIC_URL}/imgs/chat_A.png`} */
            src={`${process.env.REACT_APP_IMG_URL}web_img/chat_A.png`}
            className="MainContent-animation_chat"
            alt=""
          />
        )}
      </div>
      {showResult && (
        <ChatProcess
          showHandler={setShowResult}
          LANG={LANG}
          STYLE={STYLE}
          QUESTION={null}
        />
      )}
    </div>
  );
};

export default MainContent;
