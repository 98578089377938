import axios from "axios";

export function get(url, data, headers, abortController, timeout, cb) {
  abortController =
    abortController === null ? new AbortController() : abortController;
  timeout =
    timeout === null ? parseInt(process.env.REACT_APP_API_TIMEOUT) : timeout;
  axios
    .get(url, { signal: abortController.signal, timeout: timeout })
    .then((response) => {
      const res = response.data;
      cb(null, res);
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        if (process.env.NODE_ENV === "development") {
          console.error("Operation canceled");
        }
      } else if (err.code === "ECONNABORTED") {
        if (process.env.NODE_ENV === "development") {
          console.log(err);
          console.log("Request timed out");
        }
        cb(err); // Call cb with the error even if it is a timeout error
      } else {
        cb(err); // Call cb with the error for any other errors
      }
    });
}

export function post(
  url,
  postData,
  headers,
  abortController = null,
  timeout,
  cb
) {
  abortController =
    abortController === null ? new AbortController() : abortController;
  timeout =
    timeout === null ? parseInt(process.env.REACT_APP_API_TIMEOUT) : timeout;
  axios
    .post(url, postData, {
      headers: headers,
      signal: abortController.signal,
      timeout: timeout,
    })
    .then((response) => {
      const res = response.data;
      cb(null, res);
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        if (process.env.NODE_ENV === "development") {
          console.error("Operation canceled");
        }
      } else {
        if (err.code === "ECONNABORTED") {
          if (process.env.NODE_ENV === "development") {
            console.log(err);
            console.log("Request timed out");
            cb(err);
          }
        } else {
          cb(err);
        }
      }
    });
}

export function postAll(
  url,
  postData,
  headers,
  abortController = null,
  timeout,
  cb
) {
  abortController =
    abortController === null ? new AbortController() : abortController;
  timeout =
    timeout === null ? parseInt(process.env.REACT_APP_API_TIMEOUT) : timeout;

  axios
    .post(url, postData, {
      headers: headers,
      signal: abortController.signal,
      timeout: timeout,
    })
    .then((response) => {
      cb(null, response);
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        if (process.env.NODE_ENV === "development") {
          console.error("Operation canceled");
        }
      } else {
        if (err.code === "ECONNABORTED") {
          if (process.env.NODE_ENV === "development") {
            console.log(err);
            console.log("Request timed out");
            cb(err);
          }
        } else {
          cb(err);
        }
      }
    });
}
