import { useIntl } from "react-intl";
import "../styles/FullScreenContent.scss";

const FullScreenContent = (props) => {
  const { LANG, STYLE, content, showContent } = props;

  const intl = useIntl();

  return (
    <div className="FullScreenContent">
      <div className="FullScreenContent-closeBtn" onClick={showContent}>
        {intl.formatMessage({ id: "ChatProcess.closeText" })}
      </div>
      <div className="FullScreenContent-content">{content}</div>
    </div>
  );
};

export default FullScreenContent;
