import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Landing.scss";
import TelegramCode from "./TelegramCode.js";
import ChatProcess from "../components/ChatProcess.js";
import GiftCode from "../components/GiftCode.js";
import Footer from "../components/Footer.js";
import messages from "../languages.json";
import { SessionContext } from "../contexts/sessionContext.js";
import userLog from "../utils/userLog.js";

import { Helmet } from "react-helmet";

const Landing = (props) => {
  const navigate = useNavigate();
  const { SHOW_CHAT } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [showTelegram, setShowTelegram] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const metaDescription = messages["zh-TW"]["IChing.description"];
  const siteTitle = `${process.env.REACT_APP_ICHING_TITLE_TC}`;
  const { userSign } = useContext(SessionContext);
  const isFirstRender = useRef(true);
  const defaultQuestion = useRef(null);

  const preSetQuestions = [
    { q: "上司非常不負責任和偏心，我該怎辦？", classify: "career" },
    { q: "這個月的偏財運如何？", classify: "wealth" },
    { q: "一年內有升遷機會嗎？還是應該轉職？", classify: "career" },
    { q: "近期買入美股是否好時機？", classify: "wealth" },
  ];

  useEffect(() => {
    if (isFirstRender.current) {
      try {
        userLog("landing", userSign, "zh-TW", null, (err, res) => {
          if (err) {
          } else {
            isFirstRender.current = false;
          }
        });
      } catch (error) {
        console.error("Caught exception:", error);
      }
    }
    setShowChat(SHOW_CHAT);
  }, []);

  const openTelegramBot = () => {
    window.open(
      "https://t.me/MirrorcleBot?start=giftcodehJ5FH-JFRvH-HEJRX-kL6kG",
      "_blank"
    );
  };

  const preSetQuestion1 = () => {
    defaultQuestion.current = preSetQuestions[0];
    displayChat();
  };

  const preSetQuestion2 = () => {
    defaultQuestion.current = preSetQuestions[1];
    displayChat();
  };

  const preSetQuestion3 = () => {
    defaultQuestion.current = preSetQuestions[2];
    displayChat();
  };

  const preSetQuestion4 = () => {
    defaultQuestion.current = preSetQuestions[3];
    displayChat();
  };

  const noPreSetQuestion = () => {
    defaultQuestion.current = null;
    displayChat();
  };

  const displayDialog = () => {
    userLog("invitation_card", userSign, "zh-TW", (err, res) => {});
    setShowDialog(true);
  };

  const displayTelegram = () => {
    setShowTelegram(true);
  };

  const displayChat = () => {
    navigate("chat");
    setShowChat(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const closeTelegram = () => {
    setShowTelegram(false);
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="Landing">
        <div className="Landing-content">
          {showTelegram && (
            <TelegramCode
              closeTelegram={closeTelegram}
              LANG="zh-TW"
              STYLE="2"
            />
          )}

          {showChat && (
            <ChatProcess
              showHandler={setShowChat}
              LANG="zh-TW"
              STYLE="2"
              QUESTION={defaultQuestion.current}
            />
          )}

          <div className="Landing-content__logo">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/imgs/logo2.png`}
              className="Landing-logo"
            />
          </div>

          <div className="Landing-content__img_frame">
            <img
              src={`${process.env.PUBLIC_URL}/imgs/mc_001.png`}
              alt=""
              className="Landing-content__img"
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/qs_002.png`}
              alt=""
              className="Landing-content__img Landing-clickable"
              onClick={preSetQuestion1}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/qs_003.png`}
              alt=""
              className="Landing-content__img Landing-clickable"
              onClick={preSetQuestion2}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/qs_004.png`}
              alt=""
              className="Landing-content__img Landing-clickable"
              onClick={preSetQuestion3}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/qs_005.png`}
              alt=""
              className="Landing-content__img Landing-clickable"
              onClick={preSetQuestion4}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/qs_006.png`}
              alt=""
              className="Landing-content__img Landing-clickable"
              onClick={noPreSetQuestion}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/qs_007.png`}
              alt=""
              className="Landing-content__img "
            />
            {/*  <img
              src={`${process.env.PUBLIC_URL}/imgs/qs_008.png`}
              alt=""
              className="Landing-content__img Landing-clickable"
            /> */}

            <img
              src={`${process.env.PUBLIC_URL}/imgs/mc_003.png`}
              alt=""
              className="Landing-content__img"
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/mc_004.png`}
              alt=""
              className="Landing-content__img"
            />
            {/* <img
              src={`${process.env.PUBLIC_URL}/imgs/mc_004.png`}
              alt=""
              className="Landing-content__img Landing-clickable"
              onClick={displayDialog}
            /> */}
            {showDialog && (
              <div className="Landing-dialog">
                <div
                  className="Landing-dialog__close Landing-clickable"
                  onClick={closeDialog}
                >
                  X
                </div>
              </div>
            )}
            {/* <div className="Landing-dialog__content">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/card001.png`}
                alt=""
                className="Landing-content__img4"
              />
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/imgs/telegramDemo1.jpg`}
                className="Landing-dialog__img3"
              />
              <img
                src={`${process.env.PUBLIC_URL}/imgs/card002.png`}
                alt=""
                className="Landing-dialog__img2"
              />
              <img
                src={`${process.env.PUBLIC_URL}/imgs/webDemo.jpg`}
                alt=""
                className="Landing-dialog__img3"
              />
              <div
                className="Landing-dialog__button"
                onClick={noPreSetQuestion}
              >
                免費試占一次
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/imgs/card003.png`}
                alt=""
                className="Landing-content__img3"
              />
            </div> */}
            <div className="Landing-giftCodebg">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/qs_009.png`}
                alt=""
                className="Landing-content__img2"
              />
              <GiftCode LANG="zh-TW" showChatHandler={setShowChat} STYLE="2" />
              <img
                src={`${process.env.PUBLIC_URL}/imgs/qs_009.png`}
                alt=""
                className="Landing-content__img2"
              />
              <div
                style={{
                  width: "90%",
                  textAlign: "center",
                  padding: "8px 0px 9px",
                  fontSize: "15px",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
                className="Landing-dialog__button"
                onClick={openTelegramBot}
              >
                在Telegram上占卜
              </div>
            </div>
          </div>

          <Footer LANG="zh-TW" STYLE="2" />
        </div>
      </div>
    </>
  );
};

export default Landing;
