import { React, useEffect, useContext } from "react";
import AppInfo from "../components/AppInfo.js";
import MainContent from "../components/MainContent.js";
import Footer from "../components/Footer.js";
import messages from "../languages.json";
import userLog from "../utils/userLog.js";
import { SessionContext } from "../contexts/sessionContext.js";
import { Helmet } from "react-helmet";

function IChing(props) {
  const { LANG } = props;
  const { userSign } = useContext(SessionContext);
  const metaDescription = messages[LANG]["IChing.description"];
  const siteTitle =
    LANG === "en"
      ? `${process.env.REACT_APP_ICHING_TITLE_EN}`
      : `${process.env.REACT_APP_ICHING_TITLE_TC}`;

  useEffect(() => {
    userLog("root", userSign, LANG, null, (err, res) => {
      if (LANG === "en") {
        document.title = `${process.env.REACT_APP_ICHING_TITLE_EN}`;
      } else {
        document.title = `${process.env.REACT_APP_ICHING_TITLE_TC}`;
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>

      <AppInfo />
      <MainContent LANG={LANG} />
      <Footer LANG={LANG} STYLE="1" />
    </>
  );
}

export default IChing;
