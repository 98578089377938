import React from "react";
import "../styles/AppInfo.scss";
import { FormattedMessage } from "react-intl";

const AppInfo = () => {
  return (
    <div className="AppInfo">
      <img
        src={`${process.env.PUBLIC_URL}/imgs/logo.png`}
        className="AppInfo-logo"
        alt=""
      />
      <div className="AppInfo-introText1">
        <FormattedMessage id="AppInfo.introText1" />
      </div>
      <div className="AppInfo-introText2">
        <FormattedMessage id="AppInfo.introText2" />
      </div>
    </div>
  );
};

export default AppInfo;
