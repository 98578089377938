import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import TelegramLogin from "../components/TelegramLogin.js";
import userLog from "../utils/userLog.js";
import { SessionContext } from "../contexts/sessionContext.js";
import "../styles/ChatProcess.scss";
import "../styles/TelegramCode.scss";

export const TelegramCode = (props) => {
  const { closeTelegram, LANG, STYLE } = props;
  const telegram_t_codeMap = { en: "t_code_en", ch: "t_code_tc2" };
  const telegram_redeem_codeMap1 = { en: "redeem1_en", ch: "redeem1_tc" };
  const telegram_redeem_codeMap2 = { en: "redeem2_en", ch: "redeem2_tc" };
  const langMap = { en: "en", "zh-TW": "ch" };
  const intl = useIntl();
  const { userSign } = useContext(SessionContext);

  useEffect(() => {
    userLog("enter_telegram", userSign, "zh-TW", (err, res) => {});
  }, []);

  return (
    <div className={STYLE === "1" ? "TelegramCode-bg" : "TelegramCode-bg2"}>
      <div
        className={
          STYLE === "1" ? "TelegramCode-telegramBg" : "TelegramCode-telegramBg2"
        }
      >
        <div className="TelegramCode-telegramBg_header">
          <div
            className={
              STYLE === "1"
                ? "TelegramCode-telegramBg_closeBtn"
                : "TelegramCode-telegramBg2_closeBtn"
            }
            onClick={closeTelegram}
          >
            {intl.formatMessage({ id: "ChatProcess.close" })}
          </div>
        </div>
        <div className="ChatProcess-telegramBg_telegramFrame">
          <div>{intl.formatMessage({ id: "ChatProcess.telegramExplain" })}</div>
          <div>
            <TelegramLogin LANG={LANG} STYLE={STYLE} />
          </div>
          <br />
          <div>
            {intl.formatMessage({ id: "ChatProcess.telegramExplain1" })}
          </div>
          <br />
          <img
            src={`${process.env.REACT_APP_IMG_URL}web_img/accept_telegram.jpg`}
            className="ChatProcess-telegramBg_img1"
            alt=""
          />
          <div>{intl.formatMessage({ id: "ChatProcess.figure1" })}</div>
          <br />
          <div>
            {intl.formatMessage({ id: "ChatProcess.telegramExplain2" })}
          </div>
          <br />
          <img
            src={`${process.env.REACT_APP_IMG_URL}web_img/accept_telegram2.jpg`}
            className="ChatProcess-telegramBg_img1"
            alt=""
          />
          <div>{intl.formatMessage({ id: "ChatProcess.figure2" })}</div>
          <br />
          <div>
            {intl.formatMessage({ id: "ChatProcess.telegramExplain3" })}
          </div>
          <br />
          <img
            src={`${process.env.REACT_APP_IMG_URL}web_img/${
              telegram_t_codeMap[langMap[LANG]]
            }.jpg`}
            className="ChatProcess-telegramBg_img1"
            alt=""
          />
          <div>{intl.formatMessage({ id: "ChatProcess.figure3" })}</div>
          <br />
          <div>
            {intl.formatMessage({ id: "ChatProcess.telegramExplain4" })}
          </div>
          <br />
          <img
            src={`${process.env.REACT_APP_IMG_URL}web_img/${
              telegram_redeem_codeMap1[langMap[LANG]]
            }.jpg`}
            className="ChatProcess-telegramBg_img1"
            alt=""
          />
          <div>{intl.formatMessage({ id: "ChatProcess.figure4" })}</div>
          <br />
          <div>
            {intl.formatMessage({ id: "ChatProcess.telegramExplain5" })}
          </div>
          <br />
          <img
            src={`${process.env.REACT_APP_IMG_URL}web_img/${
              telegram_redeem_codeMap2[langMap[LANG]]
            }.jpg`}
            className="ChatProcess-telegramBg_img1"
            alt=""
          />
          <div>{intl.formatMessage({ id: "ChatProcess.figure5" })}</div>
        </div>
      </div>
    </div>
  );
};

export default TelegramCode;
