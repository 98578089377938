import { post } from "../utils/HttpRequest.js";

export default (action, userSign, LANG, sessionId, cb) => {
  const urlApiMap = {
    en: process.env.REACT_APP_API_EN_URL,
    "zh-TW": process.env.REACT_APP_API_TC_URL,
  };
  const confirmUrl = `${urlApiMap[LANG]}/acceptUser`;
  const nowMS = new Date().valueOf();
  if (userSign.length === 64) {
    const result = {
      isotime: nowMS,
      uHash: userSign,
      action: action,
    };
    if (sessionId) result["sessionId"] = sessionId;
    post(confirmUrl, result, null, null, null, (err, res) => {
      if (err) {
        cb(err);
      } else {
        cb(null, err);
      }
    });
  }
};
