import "../styles/TelegramLogin.scss";
import { useState, useEffect, useContext } from "react";
import { post } from "../utils/HttpRequest.js";
import { FormattedMessage, useIntl } from "react-intl";
import { SessionContext } from "../contexts/sessionContext.js";

const TelegramLogin = (props) => {
  const { LANG, STYLE } = props;
  const { userSign } = useContext(SessionContext);
  const intl = useIntl();
  const [isAuth, setIsAuth] = useState(false);
  const [sn, setSn] = useState(
    intl.formatMessage({
      id: "TelegramLogin.gettingCode",
    })
  );

  const urlMap = {
    en: process.env.REACT_APP_TELEGRAM_BOT_EN_URL,
    "zh-TW": process.env.REACT_APP_TELEGRAM_BOT_TC_URL,
  };

  const botNameMap = {
    en: process.env.REACT_APP_TELEGRAM_BOT_NAME_EN,
    "zh-TW": process.env.REACT_APP_TELEGRAM_BOT_NAME_TC,
  };

  const urlApiMap = {
    en: process.env.REACT_APP_API_EN_URL,
    "zh-TW": process.env.REACT_APP_API_TC_URL,
  };

  const getUserSn = (chatId, userSign, cb) => {
    let code = "";
    const url = `${urlApiMap[LANG]}/get_trail_code`;
    const postData = JSON.stringify({
      isotime: new Date().valueOf(),
      uHash: userSign,
      chatId: chatId,
      action: "get_code",
    });
    const headers = {
      "Content-Type": "application/json",
    };

    let abortController = new AbortController();
    post(url, postData, headers, abortController, null, (err, response) => {
      if (err) {
        console.log(err);
      } else {
        code = response.code;
        cb(code);
      }
    });
  };

  useEffect(() => {
    window.handleTelegramAuth = (user) => {
      // Handle the successful authentication here
      setIsAuth(true);
      getUserSn(user.id.toString(), userSign, (userSn) => {
        setSn(userSn);
      });
    };

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?7";
    script.setAttribute("data-telegram-login", botNameMap[LANG]);
    script.setAttribute("data-size", "medium");
    script.setAttribute("data-radius", "10");
    //script.setAttribute('data-auth-url', 'YOUR_AUTHENTICATION_URL');

    // Set the callback function for successful authentication
    script.setAttribute("data-onauth", "handleTelegramAuth(user)");
    document.getElementById("telegram-login").appendChild(script);
    // Clean up the script when the component unmounts
    return () => {
      //document.getElementById("telegram-login").removeChild(script);
    };
  }, [isAuth, sn]);

  const handleSnClose = () => {
    setIsAuth(false);
  };

  const copyCode = async () => {
    const copyText = document.getElementById("TelegramCode").textContent;
    await window.navigator.clipboard.writeText(copyText);
    window.open(urlMap[LANG], "_blank");
  };

  return (
    <div id="telegram-login" className="TelegramLogin">
      {isAuth && (
        <div
          className={STYLE === "1" ? "TelegramLogin-bg" : "TelegramLogin-bg2"}
        >
          <div
            className={
              STYLE === "1" ? "TelegramLogin-snBox" : "TelegramLogin-snBox2"
            }
          >
            <div
              className={
                STYLE === "1"
                  ? "TelegramLogin-snBox_close"
                  : "TelegramLogin-snBox2_close2"
              }
              onClick={handleSnClose}
            >
              <FormattedMessage id="TelegramLogin.goback" />
            </div>
            <div className="TelegramLogin-snBox_text">
              <FormattedMessage id="TelegramLogin.telegramTrialCode"></FormattedMessage>
              <br />
            </div>
            <div id="TelegramCode" className="TelegramLogin-snBox_code">
              {sn}
            </div>
            <div
              className={
                STYLE === "1"
                  ? "TelegramLogin-snBox2_copyBtn2"
                  : "TelegramLogin-snBox2_copyBtn2"
              }
              onClick={copyCode}
            >
              <FormattedMessage id="TelegramLogin.copy" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TelegramLogin;
