import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useIntl } from "react-intl";
import "../styles/CheckoutForm.scss";

export const CheckoutForm = (props) => {
  const intl = useIntl();
  const { clientSecret, changeShowPay, handleSuccessPay } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dollar = "USD$2";

  useEffect(() => {
    /* const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    */

    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost",
      },
      redirect: "if_required",
    });
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    }
    setIsLoading(false);
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          changeShowPay();
          handleSuccessPay();
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div className="CheckoutForm-bg">
      <div onClick={changeShowPay} className="CheckoutForm-closeBtn">
        {intl.formatMessage({ id: "ChatProcess.closeText" })}
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/imgs/stripe.svg`}
        className="CheckoutForm-logo"
        alt=""
      />
      <div className="CheckoutForm-dollarFont">{dollar}</div>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        className="CheckoutForm-form"
      >
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="CheckoutForm-submit"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
};

export default CheckoutForm;
